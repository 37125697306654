import axios from "axios";

var domain=window.location.host; //获取当前域名(ip)和端口号
//let apiURL = "https://www.pdfgo.cc/api"
//动态获取域名，为了服务器跨域访问
let apiURL = "https://"+domain+"/api"
//console.log("域名："+domain);
// let openid = localStorage.getItem("openid")
let openid = "oWcCS5b5HwnV5qmuAggxuJtHtVto"

class apiService {
    getopenid(work_type) {
        let keyword;
        switch (work_type) {
            case 'pdf_to_word':
                keyword = 'pdftoword'
                break
            case 'pdf_to_excel':
                keyword = 'pdftoexcel'
                break
            case 'pdf_to_ppt':
                keyword = 'pdftoppt'
                break
            case 'pdf_to_html':
                keyword = 'pdftohtml'
                break
            case 'pdf_to_img':
                keyword = 'pdftopic'
                break
            case 'pdf_to_txt':
                keyword = 'pdftotext'
                break
            case 'word_to_pdf':
                keyword = 'wordtopdf'
                break
            case 'excel_to_pdf':
                keyword = 'exceltopdf'
                break
            case 'ppt_to_pdf':
                keyword = 'ppttopdf'
                break
            case 'html_to_pdf':
                keyword = 'htmltopdf'
                break
            case 'img_to_pdf':
                keyword = 'pictopdf'
                break
            case 'txt_to_pdf':
                keyword = 'txttopdf'
                break
            case 'lock_pdf':
                keyword = 'encryptpdf'
                break
            case 'unlock_pdf':
                keyword = 'decryptpdf'
                break
            case 'logo_pdf':
                keyword = 'addwatermark'
                break
            case 'unlogo_pdf':
                keyword = 'delwatermark'
                break
            case 'reset_pdf':
                keyword = 'pdfresetpwd'
                break
            case 'merge_pdf':
                keyword = 'mergepdf'//pdf合并
                break
            case 'split_pdf':
                keyword = 'splitpdf'
                break
            case 'zip_pdf':
                keyword = 'pdftozip'
                break
            case 'rotate_pdf':
                keyword = 'pdftorotate'
                break
            case 'pdf_replace_str':
                keyword = 'pdfreplacetxt'
                break
            case 'del_str_pdf':
                keyword = 'pdfreplacetxt'
                break
            case 'pdf_extract_img':
                keyword = 'getpdfpic'
                break
            case 'del_pdf':
                keyword = 'pdfdelete'
                break
            case 'title_pdf':
                keyword = 'pdfaddheard'
                break
            case 'pages_pdf':
                keyword = 'pdfaddpagenum'
                break
            case 'word_str_replace':
                keyword = 'wordreplacetxt'
                break
            case 'word_del_str':
                keyword = 'wordreplacetxt'
                break
            case 'img_sumiao':
                keyword = 'pictostyle' //无此接口
                break
            case 'img_dongman':
                keyword = 'pictostyle' //无此接口
                break
            case 'img_changtu':
                keyword = 'pictolong'
                break
            case 'html_to_img':
                keyword = 'htmltopic'
                break
            case 'img_logo':
                keyword = 'pictomark'
                break
            default:
                keyword = null
        }
        return keyword
    }

    get_data(work_type, fileName) {
        (work_type && fileName) || (() => {
            throw Error('缺少必填参数')
        })()
        let data = {
            "here": "web",
            "openid": openid,
            "appKey": "wxncvt90988",
            "opid": this.getopenid(work_type),
            "fileName": fileName,
            "inFile": 'uploads/' + fileName
        }
        return data
    }

  
    html_data(work_type) {
        (work_type) || (() => {
            throw Error('缺少必填参数')
        })()
        let data = {
            "here": "web",
            "openid": openid,
            "appKey": "wxncvt90988",
            "opid": this.getopenid(work_type)
        }
        return data
    }
    
    get_merge_data(work_type, inPdfList) { //pdf合并
        (work_type && inPdfList) || (() => {
            throw Error('缺少必填参数')
        })()
        let data = {
            "here": "web",
            "openid": openid,
            "appKey": "wxncvt90988",
            "opid": this.getopenid(work_type),
            "inPdfList": inPdfList
        }
        return data
    }

    get_multiple_data(work_type, listPics) {//长图拼接
        (work_type && listPics) || (() => {
            throw Error('缺少必填参数')
        })()
        
        let pics = listPics.split(',');

        let data = {
            "here": "web",
            "openid": openid,
            "appKey": "wxncvt90988",
            "opid": this.getopenid(work_type),
            "pics": pics
        }
        return data
    }




    async coverter_upload(vc, data, work_type) {
        console.log(work_type)
        await axios.post(apiURL + "/pdf/" + this.getopenid(work_type), data).then(
            (response) => {
                if (response.data.code == 200) {
                    vc.$message({
                        type: "success",
                        message: "转换成功，正在下载...",
                        offset: 120,
                        duration: 3000,
                    });
                    vc.$router.push("/download?file=" + response.data.outFile);
                    // window.open(apiURL + "/updown/download?dfile=" + response.data.outFile)
                } else {
                    vc.$message({
                        type: "error",
                        message: response.data.msg,
                        duration: 3000,
                    });
                }
            },
            (error) => {
                vc.$message({
                    type: "error",
                    message: "转换失败，请重新上传文件",
                    duration: 3000,
                });
            }
        )
    }

    common_to(vc, work_type, fileName) { //单文件的
        let data = this.get_data(work_type, fileName)
        this.coverter_upload(vc, data, work_type)
    }



    async get_lab() {//获取文库
        let data = {
            "openid": openid,
            "appKey": "wxncvt90988",
        }
        var tableData = await axios.post(apiURL + "/comcontrol/getlabs",data)
        return tableData.data
    }

    async lab_del(fid){//删除文库
        let data = {
            "fid":fid,
            "appKey": "wxncvt90988"
        }
        var tableData = axios.post(apiURL + "/comcontrol/delfilelab", data)
        return tableData
    }






    pdf_img_split(vc, work_type, fileName, choice, page, epage = null) { //pdf转图片和pdf拆分
        if (choice == 'range') {
            page = `${page}-${epage}`
        }
        let data = {
            "choice": choice,
            "page": page,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }




    




    img_logo(vc, work_type, fileName, mark, color, size, opacity) { //图片加水印
        let data = {
            "mark": mark,
            "color": color,
            "size": size,
            "opacity": opacity,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }





    about_pwd(vc, work_type, fileName, password) { //pdf加密和解密
        let data = {
            "inContent": password,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }


    logo_pdf(vc, work_type, fileName, watermark) { //加水印pdf文件
        let data = {
            "inContent": watermark,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }


    reset_pdf(vc, work_type, fileName, oldpassword, newpassword) { //pdf重置密码
        let data = {
            "oldinContent": oldpassword,
            "newinContent": newpassword,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }

    rep_pdf_word_str(vc, work_type, fileName, oldstring, newstring) { //pdf文字替换和word文字替换
        let data = {
            "old_string": oldstring,
            "new_string": newstring,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }



    del_pdf_word_str(vc, work_type, fileName, oldstring, newstring) { //pdf文字删除和word文字删除
        let data = {
            "old_string": oldstring,
            "new_string": newstring,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }


    html_converter(vc, work_type, inurl) { //html转图片和html转pdf
        let data = {
            "inurl": inurl,
            ...this.html_data(work_type)
        }
        this.coverter_upload(vc, data, work_type)
    }

    rotate_pdf(vc, work_type, fileName, rotate, direction) {//pdf旋转（不会写）
        let data = {
            "direction": direction,
            "rotate":rotate,

            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }


    del_pdf(vc, work_type, fileName, choice, page, epage = null) { //pdf页面删除
        if (choice == 'range') {
            page = `${page}-${epage}`
        }
        let data = {
            "choice": choice,
            "page": page,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }


    title_pdf(vc, work_type, fileName, heardtxt) { //添加页眉文字
        let data = {
            "heardtxt": heardtxt,
            ...this.get_data(work_type, fileName)
        }
        this.coverter_upload(vc, data, work_type)
    }

    img_to_pdf(vc, work_type,listPics){//长图拼接
        console.log(listPics)
        let data = {
            ...this.get_multiple_data(work_type,listPics )
        }
        console.log(data)
        this.coverter_upload(vc, data, work_type)
    }



    pdf_multifile(vc, work_type, inPdfList) {
        console.log(inPdfList)
        let data = {
            ...this.get_merge_data(work_type, inPdfList)
        }
        this.coverter_upload(vc, data, work_type)
    }

    
    pic_mul_long(vc, work_type,listPics){//长图拼接
        console.log(listPics)
        let data = {
            ...this.get_multiple_data(work_type,listPics )
        }
        console.log(data)
        this.coverter_upload(vc, data, work_type)
    }




}
export default apiService;